import React, { useState } from 'react';

const FAQAccordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const images = [
    'https://www.shutterstock.com/image-vector/simple-gray-avatar-icons-representing-600nw-2473353263.jpg',
    'https://dummyimage.com/600x400/000/fff',
    'https://www.shutterstock.com/image-illustration/robot-crash-test-dummy-sitting-600nw-2051238215.jpg',
    'https://ergobaby.co.uk/blog/wp-content/uploads/2023/08/Baby-with-Dad-Dummy.jpg',
    'https://www.shutterstock.com/image-vector/simple-gray-avatar-icons-representing-600nw-2473353263.jpg',
    'https://www.shutterstock.com/image-illustration/robot-crash-test-dummy-sitting-600nw-2051238215.jpg',
    'https://ergobaby.co.uk/blog/wp-content/uploads/2023/08/Baby-with-Dad-Dummy.jpg'
  ];

  return (
    <div className="max-w-screen-xl mx-auto p-10 dark:bg-black">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 p-4">
          <h2 className='text-4xl font-extrabold dark:text-white'>Industries We Serve</h2>
          <p className='mt-4 dark:text-white'><strong>Tired of the same old business tools? We're tech alchemists, turning  cutting-edge advancements into solutions that disrupt industries and ignite innovation. </strong> Our secret formula? Combining the magic of human ingenuity with the hottest tech trends. The result? Competitive advantages so potent, your rivals will be left in the dust.</p>
          <div className="bg-gray-200 dark:bg-gray-700 p-6 mt-4">
            <img
              src={images[openIndex - 1] || 'default.jpg'} // Replace 'default.jpg' with the path to your default image
              alt="Accordion related"
              className="w-full h-auto"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <div id="accordion-flush" data-accordion="collapse">
            {[{
              id: 1,
              question: 'Real Estate',
              answer: `Don't miss out on qualified leads! Become your region's top realtor with our data-driven approach to digital real estate marketing. Our SEO expertise ensures your listings dominate local searches, putting you in front of motivated buyers. We go beyond ranking with engaging social media campaigns and informative content that builds trust and brand awareness. We constantly analyze results and optimize your strategy to maximize impact, reaching the perfect audience. \n Partner with us and unlock explosive growth for your real estate business.`
            }, {
              id: 2,
              question: 'Healthcare',
              answer: `Web Visionary Media offers digital marketing solutions to help healthcare providers thrive online. Their SEO services ensure your website ranks high in searches, attracting organic traffic. They can also manage targeted PPC ads to reach new patients actively seeking healthcare services. Social media marketing allows you to connect with your community, share informative content, and build trust. Valuable content marketing positions your practice as a trusted source of information. \n Email marketing helps you nurture patient relationships and encourage repeat visits. Finally, they design user-friendly, mobile-responsive websites that prioritize security and SEO. With their industry expertise, compliance focus, and data-driven approach, Web Visionary Media helps healthcare providers create a positive patient experience throughout their online journey.`
            }, {
              id: 3,
              question: 'Astrology',
              answer: `Astrology, an ancient practice that continues to captivate millions, has found a new platform in the digital age. As an astrologer, reaching and engaging with your audience online is more important than ever. At Web Visionary Media, we specialise in digital marketing strategies tailored to elevate your astrology business. We aim to help you expand your reach, build credibility, and grow your client base through innovative and effective digital marketing solutions.`
            }, {
              id: 4,
              question: 'Immigration',
              answer: `In today’s digital age, a strong online presence is crucial for businesses in every industry, including immigration services. Whether you are a law firm specializing in immigration, a consultancy helping clients navigate visa applications, or an agency offering relocation services, having a robust digital marketing strategy can set you apart from the competition and help you reach more potential clients. At [Your Company Name], we offer comprehensive digital marketing solutions tailored specifically for the immigration sector. Here’s how we can help elevate your business.`
            },{
              id: 5,
              question: 'Restaurant',
              answer: `In today's digital age, having a solid online presence is essential for the success of any restaurant. At Web Visionary Media, we specialize in helping restaurants like yours thrive by leveraging cutting-edge digital marketing strategies. Our comprehensive services are designed to increase your visibility, attract customers, and build a loyal following. Here’s how we can elevate your restaurant business to new heights`
            },{
              id: 6,
              question: 'Education Sector',
              answer: `In today's digital age, educational institutions face unique challenges and opportunities. With the growing competition, the need to attract prospective students, engage current students, and maintain strong alumni relationships has never been greater. At [Your Company Name], we specialize in helping educational institutions navigate the digital landscape and elevate their brand. Our comprehensive digital marketing solutions are tailored to meet the specific needs of the education sector, ensuring your institution stands out and achieves its goals.`
            },{
              id: 7,
              question: 'Law Firm',
              answer: `In today's digital age, having a solid online presence is crucial for any law firm aiming to attract and retain clients. At Web Visionary Media, we specialize in providing tailored digital marketing solutions that elevate your law firm's visibility, credibility, and client engagement. Our comprehensive approach ensures that your firm stands out in a competitive market, drives more qualified leads, and builds lasting client relationships.`
            }].map((item, index) => (
              <div key={item.id}>
                <h3 id={`accordion-flush-heading-${item.id}`}>
                  <button
                    type="button"
                    className={`flex items-center justify-between w-full py-5 px-4 font-medium text-left border-b border-gray-200 dark:border-gray-700
                    ${openIndex === item.id ? 'bg-white dark:bg-gray-900 text-gray-900 dark:text-white' : 'text-gray-500 dark:text-gray-400'}`}
                    onClick={() => toggleAccordion(item.id)}
                    aria-expanded={openIndex === item.id}
                    aria-controls={`accordion-flush-body-${item.id}`}
                  >
                    <span>{item.question}</span>
                    <svg
                      className={`w-6 h-6 shrink-0 transition-transform ${openIndex === item.id ? 'rotate-180' : ''}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h3>
                <div
                  id={`accordion-flush-body-${item.id}`}
                  className={`py-5 border-b border-gray-200 dark:border-gray-700 ${openIndex === item.id ? '' : 'hidden'}`}
                  aria-labelledby={`accordion-flush-heading-${item.id}`}
                >
                  <p className="mb-2 text-gray-500 dark:text-gray-400">{item.answer.split('\n')[0]}</p>
                  <p className="text-gray-500 dark:text-gray-400">{item.answer.split('\n')[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQAccordion;
