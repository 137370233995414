// Home.js
import React from 'react';
import { cn } from "../lib/utils";
import DotPattern from "../components/dot-pattern";
import IconCloud from "../components/icon-cloud";
import Card from '../components/Card';
import VelocityText from '../components/VelocityText';
import WhyUs from '../components/WhyUs';
import { MarqueeDemo } from '../components/Testimonial';
import Cta from '../components/Cta';

const slugs = [
  "typescript",
  "javascript",
  "dart",
  "java",
  "react",
  "flutter",
  "android",
  "html5",
  "css3",
  "nodedotjs",
  "express",
  "nextdotjs",
  "prisma",
  "amazonaws",
  "postgresql",
  "firebase",
  "nginx",
  "vercel",
  "testinglibrary",
  "jest",
  "cypress",
  "docker",
  "git",
  "jira",
  "github",
  "gitlab",
  "visualstudiocode",
  "androidstudio",
  "sonarqube",
  "figma",
];

function Home() {
  return (
    <div className='bg-white dark:bg-black dark:text-white p-1 md:p-20'>
      <section>
      <div className="flex flex-wrap justify-center items-center">
  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/2 p-4">
    <div className="two">

        <h1 className='text-slate-800 dark:text-white'>Empowering Your Digital Future<span>with Expert Innovative Solutions!</span></h1>

    </div>
  </div>
  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/2 p-4">
    <div className="sm:p1 md:p-4">
      <IconCloud iconSlugs={slugs} />
    </div>
  </div>
</div>
      
      <DotPattern
        className={cn(
          "[mask-image:radial-gradient(300px_circle_at_center,white,transparent)]",
        )}
      />
      </section>

    <section className='mt-12'>
      <div className='three mb-6'><h2>Our Services</h2></div>
    <div className="grid grid-cols-12 gap-4">
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Search engine optimisation (SEO)"
            description="Improving search engine ranking through on-page and off-page SEO strategies"
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/digital-marketing-5507151-4596633.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Pay-Per-Click (PPC) Advertising"
            description="Target your audience with precision through Google Ads and other PPC platforms."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/ppc-8113746-6497274.png"
          />

      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Social Media Advertising"
            description="Engage with your audience on platforms like Facebook, Instagram, LinkedIn, & X."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/social-media-5806306-4863035.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Digital Marketing Consulting"
            description="Develop comprehensive digital marketing strategies tailored to your business."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/businessman-5237678-4377707.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Content Marketing"
            description="Create compelling content that resonates with your audience."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/content-marketing-6097663-5013094.png?f=webp"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Web Design & Development"
            description="Build user-friendly and aesthetically pleasing websites."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/web-development-5617617-4674328.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Data Analytics & Reporting"
            description="Gain actionable insights with advanced data analytics."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/data-analytics-7640822-6149163.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Email Marketing"
            description="Create compelling & personalized email campaign" 
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/email-marketing-5402857-4521470.png"
          />
      </div>
    </div>
    </section>

    <section className='my-40'>
    <VelocityText
        primaryText={"Let's Grow Together | "}
        secondaryText={"secondaryText"}
      />
    </section>

    <section>
      <WhyUs></WhyUs>
    </section>
    <section>
    <div class="twelve">
      <h1>Testimonials</h1>
    </div>
      <MarqueeDemo></MarqueeDemo>
    </section>
    <section>
      <Cta></Cta>
    </section>
    </div>
  );
}

export default Home;