// src/components/Card.js

import React from 'react';
import PropTypes from 'prop-types';
import { BorderBeam } from "../components/border-beam";

let random_delay = Math.floor(Math.random() * (10 - 5 + 1)) + 5;;
const Card = ({ title, description, imageUrl }) => {
  return (
    <div className="h-full relative overflow-hidden bg-white dark:bg-slate-800 rounded-lg shadow-lg">
    <img src={imageUrl} alt={title} className="rounded-t-lg"/>
    <div className="p-6">
      <h2 className="font-bold mb-2 text-2xl text-purple-800 dark:text-white">{title}
      </h2>
      <p className="text-purple-700 dark:text-white mb-2">{description}</p>
      <a href="#" className="text-purple-600 dark:text-gray-500 hover:text-purple-500 dark:hover:text-white underline text-sm">Read More 👉</a>
    </div>
    <BorderBeam size={300} duration={title.length+5} delay={random_delay-title.length} />
  </div>

  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default Card;
