import { cn } from "../lib/utils";
import Marquee from "./marquee";
import React from "react";
const reviews = [
    {
        name: "Jack",
        username: "@jack",
        body: "I've never seen anything like this before. It's amazing. I love it.",
        img: "https://avatar.vercel.sh/jack",
    },
    {
        name: "Jill",
        username: "@jill",
        body: "I don't know what to say. I'm speechless. This is amazing.",
        img: "https://avatar.vercel.sh/jill",
    },
    {
        name: "John",
        username: "@john",
        body: "I'm at a loss for words. This is amazing. I love it.",
        img: "https://avatar.vercel.sh/john",
    },
    {
        name: "Jane",
        username: "@jane",
        body: "I'm at a loss for words. This is amazing. I love it.",
        img: "https://avatar.vercel.sh/jane",
    },
    {
        name: "Jenny",
        username: "@jenny",
        body: "I'm at a loss for words. This is amazing. I love it.",
        img: "https://avatar.vercel.sh/jenny",
    },
    {
        name: "James",
        username: "@james",
        body: "I'm at a loss for words. This is amazing. I love it.",
        img: "https://avatar.vercel.sh/james",
    },
];
const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);
const ReviewCard = ({ img, name, username, body, }) => {
    return (React.createElement("figure", { className: cn("relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4", 
        // light styles
        //"border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]", 
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]") },
        React.createElement("div", { className: "flex flex-row items-center gap-2" },
            React.createElement("img", { className: "rounded-full", width: "32", height: "32", alt: "", src: img }),
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("figcaption", { className: "text-sm font-medium dark:text-white" }, name),
                React.createElement("p", { className: "text-xs font-medium dark:text-white/40" }, username))),
        React.createElement("blockquote", { className: "mt-2 text-sm" }, body)));
};
export function MarqueeDemo() {
    return (React.createElement("div", { className: "relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background" },
        React.createElement(Marquee, { pauseOnHover: true, className: "[--duration:20s]" }, firstRow.map((review) => (React.createElement(ReviewCard, Object.assign({ key: review.username }, review))))),
        React.createElement(Marquee, { reverse: true, pauseOnHover: true, className: "[--duration:20s]" }, secondRow.map((review) => (React.createElement(ReviewCard, Object.assign({ key: review.username }, review))))),
        React.createElement("div", { className: "pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-black" }),
        React.createElement("div", { className: "pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-black" })));
}
