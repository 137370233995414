import React from 'react';
import ServicesWebDev from '../components/ServicesWebDev';
import ServicesWordpressDev from '../components/ServicesWordpressDev';
import ServicesShopifyDev from '../components/ServicesShopifyDev';
import ServicesAppDev from '../components/ServicesAppDev';
import ServicesEcomDev from '../components/ServicesEcomDev';

function Services(){
    return(
        <>
            <ServicesWebDev/>
            <ServicesWordpressDev/>
            <ServicesShopifyDev/>
            <ServicesAppDev/>
            <ServicesEcomDev/>
        </>
    );
}
export default Services;