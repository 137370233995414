// Contact.js
import React from 'react';
import ContactSection from '../components/ContactSection';

function Contact() {
  return (
    <>
      <ContactSection/>
    </>
  );
}

export default Contact;
