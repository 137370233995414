import { cn } from "../lib/utils";
import React from "react";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

export default function Marquee(_a) {
    var { className, reverse, pauseOnHover = false, children, vertical = false, repeat = 4 } = _a, props = __rest(_a, ["className", "reverse", "pauseOnHover", "children", "vertical", "repeat"]);
    return (React.createElement("div", Object.assign({}, props, { className: cn("group flex overflow-hidden p-2 [--duration:40s] [--gap:1rem] [gap:var(--gap)]", {
            "flex-row": !vertical,
            "flex-col": vertical,
        }, className) }), Array(repeat)
        .fill(0)
        .map((_, i) => (React.createElement("div", { key: i, className: cn("flex shrink-0 justify-around [gap:var(--gap)]", {
            "animate-marquee flex-row": !vertical,
            "animate-marquee-vertical flex-col": vertical,
            "group-hover:[animation-play-state:paused]": pauseOnHover,
            "[animation-direction:reverse]": reverse,
        }) }, children)))));
}
