import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Services02() {
  return (
    <div className="relative isolate overflow-hidden dark:bg-black bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          aria-hidden="true"
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
        >
          <defs>
            <pattern
              x="50%"
              y={-1}
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
              stroke='#2b2b2b'
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible dark:fill-gray-900 fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"  width="100%" height="100%" strokeWidth={0} />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Stunning Websites with Wordpress Dashbaord</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight dark:text-gray-200 text-gray-900 sm:text-4xl">Wordpress Development</h1>
              <p className="mt-6 text-xl leading-8 dark:text-gray-100 text-gray-700">
              A website is crucial for digital marketing. Web Visionary Media creates visually stunning, user-friendly, and functional websites for real estate, hospitality, financial services, education, and healthcare. Boost your business with our expert web design.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt=""
            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 dark:text-gray-100 text-gray-700 lg:max-w-lg">
              <h2 className='text-xl font-bold mb-2'>Why WordPress?</h2>
              <p>
              WordPress is flexible, scalable, and user-friendly, powering over 40% of websites worldwide. Its vast plugins and themes enable highly customized sites for businesses of all sizes. WordPress is also SEO-friendly, mobile responsive, and secure, making it ideal for building robust websites.
              </p>

              <h2 className='text-2xl font-bold mb-2 mt-8'>Advantages of WordPress Development</h2>
              <ul role="list" className="mt-8 space-y-8 dark:text-gray-100 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                  <span>
                    <strong className="font-semibold dark:text-gray-100 text-gray-900">Customization</strong> WordPress offers a wide range of plugins and themes, allowing you to create a highly customized and functional website tailored to your business needs.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                  <span>
                    <strong className="font-semibold dark:text-gray-200 text-gray-900">SEO-Friendly</strong> WordPress is built with search engine optimization (SEO) in mind, making it easier to improve your website’s visibility in search engine results.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                  <span>
                    <strong className="font-semibold dark:text-gray-200 text-gray-900">Ease of Use</strong> Ac tincidunt sapien
                    WordPress is user-friendly, allowing even non-technical users to manage and update their website with ease.
                  </span>
                </li>
              </ul>
              
              <h2 className="mt-16 text-2xl font-bold tracking-tight dark:text-gray-200 text-gray-900">No team? No problem.</h2>
              <p className="mt-6">
              <strong>Maintenance: </strong>We provide ongoing maintenance and support to keep your website up-to-date, secure, and fully functional.

In today's digital age, WordPress development is a versatile solution for businesses across industries. Web Visionary Media leverages WordPress to create custom, powerful websites that drive growth and engagement. Whether in real estate, hospitality, finance, education, or healthcare, our expert WordPress services help you achieve your business goals and stay competitive.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
