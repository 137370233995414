import { useId } from "react";
import React from 'react';
import { cn } from "../lib/utils";
var __rest = (this && this.__rest) || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
          if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
              t[p[i]] = s[p[i]];
      }
  return t;
};

export function DotPattern(_a) {
  var { width = 16, height = 16, x = 0, y = 0, cx = 1, cy = 1, cr = 1, className } = _a, props = __rest(_a, ["width", "height", "x", "y", "cx", "cy", "cr", "className"]);
  const id = useId();
  return (React.createElement("svg", Object.assign({ "aria-hidden": "true", className: cn("pointer-events-none absolute inset-0 h-full w-full fill-neutral-400/80", className) }, props),
      React.createElement("defs", null,
          React.createElement("pattern", { id: id, width: width, height: height, patternUnits: "userSpaceOnUse", patternContentUnits: "userSpaceOnUse", x: x, y: y },
              React.createElement("circle", { id: "pattern-circle", cx: cx, cy: cy, r: cr }))),
      React.createElement("rect", { width: "100%", height: "100%", strokeWidth: 0, fill: `url(#${id})` })));
}
export default DotPattern;