import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FieldAccordion from './FieldAccordion'

export default function ServicesAppDev() {
  return (
    <div className="relative isolate overflow-hidden dark:bg-black bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          aria-hidden="true"
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
        >
          <defs>
            <pattern
              x="50%"
              y={-1}
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
              stroke='#2b2b2b'
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible dark:fill-gray-900 fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"  width="100%" height="100%" strokeWidth={0} />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Get your android and ios app</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight dark:text-gray-200 text-gray-900 sm:text-4xl">Android/IOS App Development</h1>
              <p className="mt-6 text-xl leading-8 dark:text-gray-100 text-gray-700">
              Mobile applications are essential for businesses to connect with customers, improve efficiency, and stay competitive. Web Visionary Media specializes in Android application development, offering customized solutions for industries like real estate, hospitality, finance, education, and healthcare. Our expertise can help your business succeed in the digital era.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt=""
            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 dark:text-gray-100 text-gray-700 lg:max-w-lg">
              
              <FieldAccordion/>
              <h2 className="mt-16 text-2xl font-bold tracking-tight dark:text-gray-200 text-gray-900">No server? No problem.</h2>
              <p className="mt-6">
              Web Visionary Media offers reliable hosting and server solutions to keep your website running smoothly. Enjoy fast load times, high uptime, and robust security. Let us handle the technical details while you focus on growing your business. Choose us for a seamless, worry-free hosting experience. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
