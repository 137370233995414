
import React from 'react';
import PropTypes from 'prop-types';
import { VelocityScroll } from "./velocity";
import RetroGrid from "./retro-grid";

const VelocityText = ({ primaryText, secondaryText }) => {
  return (
    <div className='relative'>
    <VelocityScroll
      text={primaryText}
      default_velocity={3}
      className="font-display text-center text-4xl font-bold tracking-[-0.02em] text-black drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem]"
    />
    <RetroGrid />
    </div>
  );
};

VelocityText.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};

export default VelocityText;
