// About.js
import React from 'react';
import FAQAccordion from '../components/FAQAccordion';
import Card from '../components/Card';
import Team from '../components/Team';
import ResultsInNumbers from '../components/ResultsInNumbers';
import AboutHero from '../components/AboutHero';
function About() {
  return (
    <div>
      <AboutHero/>
      <Team/>
      <ResultsInNumbers/>
      <section className='p-10'>
      <div className='three mb-6 dark:text-white'><h2>Our Services</h2></div>
    <div className="grid grid-cols-12 gap-4">
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Search engine optimisation (SEO)"
            description="Improving search engine ranking through on-page and off-page SEO strategies"
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/digital-marketing-5507151-4596633.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Pay-Per-Click (PPC) Advertising"
            description="Target your audience with precision through Google Ads and other PPC platforms."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/ppc-8113746-6497274.png"
          />

      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Social Media Advertising"
            description="Engage with your audience on platforms like Facebook, Instagram, LinkedIn, & X."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/social-media-5806306-4863035.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Digital Marketing Consulting"
            description="Develop comprehensive digital marketing strategies tailored to your business."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/businessman-5237678-4377707.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Content Marketing"
            description="Create compelling content that resonates with your audience."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/content-marketing-6097663-5013094.png?f=webp"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Web Design & Development"
            description="Build user-friendly and aesthetically pleasing websites."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/web-development-5617617-4674328.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Data Analytics & Reporting"
            description="Gain actionable insights with advanced data analytics."
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/data-analytics-7640822-6149163.png"
          />
      </div>
      <div className='col-span-12 md:col-span-3'>
      <Card 
            title="Email Marketing"
            description="Create compelling & personalized email campaign" 
            imageUrl="https://cdn3d.iconscout.com/3d/premium/thumb/email-marketing-5402857-4521470.png"
          />
      </div>
    </div>
    </section>
      <FAQAccordion></FAQAccordion>
      
    </div>
  );
}

export default About;
